body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.1/font/bootstrap-icons.css");

@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@700&family=Lora&family=Mulish:wght@500&family=Poppins&family=Roboto+Slab:wght@600&family=Zilla+Slab:wght@700&display=swap');



#login-button {
  background-color: #44c662;
  font-family: 'Almarai', sans-serif;
  font-weight: bold;
  color: white;
  transition: all 0.2s linear;
}

#login-button:hover {
  background-color: white;
  border: 2px solid black;
  color: black;
  border-radius: 8px;
}

#register-link {
  text-decoration: none;
}

.login-headbox, .navbar-box {
  background-color: #24527a;
}

#basic-addon1 {
  background-color: rgb(224, 224, 224);
}

.login-table {
  border: 1px solid rgb(172, 172, 172);
  border-radius: 10px;
  overflow: hidden;
}

.login-title h3 {
  border-bottom: 1px solid rgb(172, 172, 172);
  background-color: #89c4ff;
  font-family: 'Zilla Slab', serif;
  font-weight: bold;
}

.forgot-pass{
  text-decoration: none;
  font-size: 14px;
}

.pagelogo{
  font-family: 'Roboto Slab', serif;
  font-size: 22px;
  color: white;
}

.nav-links {
  font-family: 'Lora', serif;
  font-weight: bold;
}
  
.page-header{
  font-family: 'Mulish', sans-serif;
  font-weight: bold;
  color: #1C0C5B;
}

.normal-text{
  font-family: 'Poppins', sans-serif;
}

.dashboard-icon{
  font-size: 60px;
}

#logout-text{
  color: #ffe700;
}

#admin thead tr th{
  background-color: #38598b;
  color: white;
  font-family: 'Zilla Slab', serif;
  font-size: 19px;
}

#admin tbody tr td{
  background-color: #f5f9ee;
  color: black;
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
}

#update-button{
  background-color: #005792;
  color: white;
  font-family: 'Almarai', sans-serif;
  font-weight: bold;
  transition: all 0.2s linear;
}

#update-button:hover{
  color: #005792;
  background-color: white;
  border: 2px solid #005792;
}

#paginate-button{
  background-color: white;
  color: #005792;
  font-family: 'Almarai', sans-serif;
  font-weight: bold;
  border: 2px solid #005792;
}

#paginate-button:hover{
  opacity: 0.8;
}

#paginate-button:focus{
  color: white;
  background-color: #005792;
}

.radio-button-text{
  font-family: 'Almarai', sans-serif;
  font-weight: bold;
  color: #005792;
}

.card-title{
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 16px;
}

.input-field-text{
  font-family: 'Roboto Slab', serif;
}

.newaccount-box{
  border: 1px solid grey;
  border-radius: 9px;
  overflow: hidden;
}

.newaccount-box h2 {
  background-color: #005792;
  color: white;
}

#account-button{
  background-color: #379956;
  color: white;
  font-family: 'Almarai', sans-serif;
  font-weight: bold;
  transition: all 0.2s linear;
}

#account-button:hover{
  color:#379956;
  background-color: white;
  border: 2px solid #379956;
}

.support-boxes{
  border: 3px solid #005792;
  min-height: 570px;
  border-radius: 8px;
}

.support-boxes h3 {
  font-family: 'Almarai', sans-serif;
  font-size: 20px;
}

.support-boxes .support-box-header{
  background-color: #38598b;
  color: white;
}


#support thead tr th{
  background-color: #38598b;
  color: white;
  font-family: 'Zilla Slab', serif;
  font-size: 15px;
}

#support tbody tr td, #support-details tbody tr td{
  background-color: #f5f9ee;
  color: black;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  cursor: pointer;
}

#support-details tbody tr th{
  background-color: #38598b;
  color: white;
  font-family: 'Zilla Slab', serif;
  font-size: 15px;
  width: 140px;
}

.comment-section{
  width: 96%;
  height: 70px;
  border-radius: 5px;
  resize: none;
  font-size: 14px;
}

#update-button{
  background-color: #379956;
  color: white;
  font-family: 'Almarai', sans-serif;
  font-weight: bold;
  transition: all 0.2s ease;
}
#update-button:hover{
  color:#379956;
  background-color: white;
  border: 2px solid #379956;
}